<template>
  <v-card-text class="tw-mt-8">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-skeleton-loader v-for="index in count" :key="index" class="mx-auto tw-mb-8" type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true
    },
  },
};
</script>

<style></style>
