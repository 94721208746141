import api from "./api";

const endPoint = "/property-details";

export const detail = (id) =>
  api
    .get(`${endPoint}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const post = async (payload) => {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("description", payload.description);
  formData.append("surface_area", payload.surface_area);
  formData.append("building_area", payload.building_area);
  formData.append("price", payload.price);
  formData.append("project", payload.project_id);
  formData.append("vendor", payload.vendor_id);
  formData.append("brochure", payload.brochure);
  formData.append("thumbnail", payload.thumbnail);
  formData.append("bedroom", payload.bedroom);
  formData.append("bathroom", payload.bathroom);

  await api.post(`${endPoint}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const update = async (payload, optionalData) => {
  const formData = new FormData();
  formData.append("_method", "put");
  formData.append("id", payload.id);
  formData.append("name", payload.name);
  formData.append("description", payload.description);
  formData.append("surface_area", payload.surface_area);
  formData.append("building_area", payload.building_area);
  formData.append("price", payload.price);
  formData.append("project", payload.project_id);
  formData.append("vendor", payload.vendor_id);
  formData.append("brochure", optionalData.brochure);
  formData.append("thumbnail", optionalData.thumbnail);
  formData.append("bedroom", payload.bedroom);
  formData.append("bathroom", payload.bathroom);

  await api.post(`${endPoint}/${payload.id}`, formData);
};

export const vendors = () =>
  api
    .get("/vendors/all")
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const projects = () =>
  api
    .get("/projects/all")
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const project = (id) =>
  api
    .get(`/projects/by-vendor/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const approved = (id) =>
  api
    .get(`${endPoint}/approved/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const rejected = (id) =>
  api
    .get(`${endPoint}/rejected/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
