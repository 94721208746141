<template>
  <v-card-text>
    <v-skeleton-loader class="mx-auto tw-mt-4" type="heading"></v-skeleton-loader>
    <v-skeleton-loader class="mx-auto tw-mt-8" type="sentences"></v-skeleton-loader>
  </v-card-text>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
