export default {
  data() {
    return {
      title: "",
    };
  },
  methods: {
    setTitle() {
      const { name } = this.$route;
      this.title = name.replaceAll("-", " ");
    },
  },
  mounted() {
    this.setTitle();
  },
};
