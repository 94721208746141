<template>
  <v-overlay absolute :value="loading">
    <lottie
      :width="200"
      :height="200"
      :options="lottieOptions"
      @animCreated="handleAnimation"
    />
  </v-overlay>
</template>

<script>
import lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/triangle_loading.json";

export default {
  components: {
    lottie,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anim: null,
      lottieOptions: { animationData: animationData.default },
      animationSpeed: 2.5
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style></style>
